import React from 'react';
import { Link } from 'react-router-dom';



export default function Header() {


    return (
        <header>

            <h4 className='headerLink'> <Link style={{ textDecoration: 'none' }} to="/">CV</Link>  </h4>
            <h4 className='headerLink'> <Link style={{ textDecoration: 'none' }} to="/projects">Projects</Link> </h4>
            <h4 className='headerLink'> <Link style={{ textDecoration: 'none' }} to="/about">About</Link> </h4>

        </header>
    )
}