import React from "react";
import BigProjects from "../components/Projects/BigProjects";
import Blockchain from "../components/Projects/Blockchain";
import Node from "../components/Projects/Node";
import ReactT from "../components/Projects/React";



export default function Projects() {




    return (
        <>

            <div className="courseMiddle">

                <h1 className="HColor">Summary:</h1>
                <h4 className="HColor">Languages: </h4> <p>JavaScript, TypeScript, Solidity </p>
                <h4 className="HColor">Technologies: </h4> <p>Blockchain, React, Node.js, Mongo, SQL, GraphQL</p>
                <h4 className="HColor">Libraries: </h4> <p>jQuery, Bootstrap, Hardhat, Ethers, DFX, Next.js, Express.js, Nest.js </p>

            </div>


            <h2>Node.js</h2>

            <img style={{ width: "400px" }} className="planet" src="Saturn6.png" alt="Saturn" />

            <Node />


            <h2 className="morePadding">Blockchain</h2>

            <img className="planet" src="Uranus7.png" alt="Uranus" />

            <Blockchain />


            <h2 style={{ paddingBottom: "3%" }}>React</h2>

            <img className="planet" src="Neptune8.png" alt="Neptune" />

            <ReactT />


            <h2>Bigger Projects</h2>

            <img className="planet" src="Pluto9.png" alt="Pluto" />

            <BigProjects />

        </>
    )
}